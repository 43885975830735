import * as React from 'react';

import {
  Box,
  Collapse,
  Flex,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import CustomButton from '../components/button';
import IDEImage from '../images/IDEImage';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const OurProjectsPage = ({
  data: {
    strapi: {
      projects: { data },
    },
    strapi: { portfolio },
  },
}) => {
  const breakpoints = useBreakpoint();
  const { isOpen, onToggle } = useDisclosure();

  const renderProjectList = items => (
    <SimpleGrid
      columns={{ base: 1, xl: 3, md: 2 }}
      spacing='5'
      mt='5'
      mb={{
        base: isOpen ? '9' : 'unset',
        lg: isOpen ? '9' : 'unset',
      }}
    >
      {items?.map(project => (
        <Flex key={project.attributes.title} flexDirection='column'>
          <Image
            alt={project.attributes.image.data.attributes.alternativeText}
            src={`${process.env.GATSBY_DOMAIN_URL}/api${project.attributes.image.data.attributes.url}`}
            fit='cover'
            height={project.attributes.image.data.attributes.height}
            maxW={355}
          />
          <VStack spacing='3' mt='6' alignItems='flex-start'>
            <Text
              color='rgba(0, 0, 0, 0.87);'
              fontFamily='Poppins'
              fontWeight='normal'
              lineHeight='35px'
              fontSize='24px'
              maxW='355px'
            >
              {project.attributes.title}
            </Text>
            <Text
              color='rgba(0, 0, 0, 0.6)'
              fontFamily='Poppins'
              letterSpacing='0.15px'
              fontWeight='normal'
              lineHeight='156%'
              fontSize='16px'
              maxW='355px'
            >
              {project.attributes.description}
            </Text>
          </VStack>
        </Flex>
      ))}
    </SimpleGrid>
  );

  const collapsableList = () => {
    let list = [data].flat(1);
    let restList = [data].flat(1);
    list.splice(6);
    return (
      <>
        {renderProjectList(list)}
        <>
          <Collapse in={isOpen}>
            {renderProjectList(restList.splice(6))}
          </Collapse>
          {!isOpen && data.length > 6 ? (
            <Flex justifyContent='center' mt='20' mb={{ base: '64', lg: '9' }}>
              <CustomButton buttonText={'More'} onClick={onToggle} />
            </Flex>
          ) : null}
        </>
      </>
    );
  };

  return (
    <Layout>
      {breakpoints.xl ? (
        <Flex position='absolute' top='0' zIndex={-10}>
          <StaticImage
            placeholder='blurred'
            alt='bg'
            src='../images/TopBG.svg'
            quality={100}
            width={8400}
          />
        </Flex>
      ) : null}
      <Seo title='Our Projects' />
      <Flex alignItems='center' flexDirection='column' mt='75px'>
        <Text
          textAlign={!breakpoints.xs ? 'center' : 'unset'}
          textTransform='uppercase'
          letterSpacing='0.03em'
          fontFamily='Poppins'
          fontWeight='black'
          lineHeight='109%'
          color='#303056'
          fontSize={{ base: '44px', md: '82px', sm: '60px' }}
        >
          {portfolio.data.attributes.page.title}
        </Text>
        <Text
          textTransform='uppercase'
          letterSpacing='0.485em'
          fontFamily='Poppins'
          fontWeight='medium'
          lineHeight='109%'
          fontSize={{ base: '16px', md: '24px', sm: '20px' }}
          color='white'
        >
          {portfolio.data.attributes.page.subtitle}
        </Text>
      </Flex>
      <Flex
        alignItems='center'
        mt='24'
        mx={{ base: '4', md: 'unset' }}
        flexDirection='column'
      >
        {collapsableList()}
      </Flex>
      {breakpoints.xl ? (
        <>
          <Flex justifyContent='center'>
            <StaticImage
              src='../images/RoundArrow.png'
              placeholder='blurred'
              layout='fixed'
              alt='Arrow'
            />
          </Flex>
          <Flex alignItems='center' flexDirection='column' mt='16'>
            <VStack alignItems='center'>
              <Text
                textTransform='uppercase'
                fontFamily='Montserrat'
                letterSpacing='0.27em'
                fontWeight='medium'
                lineHeight='10%'
                fontSize={
                  breakpoints.full ? '55px' : breakpoints.xl2 ? '40px' : '35px'
                }
                color='#37404A'
              >
                ENJOY THE SERVICES PROVIDED BY
              </Text>
              <Text
                textTransform='uppercase'
                fontFamily='Montserrat'
                letterSpacing='0.27em'
                fontWeight='medium'
                lineHeight='40%'
                fontSize={
                  breakpoints.full ? '55px' : breakpoints.xl2 ? '40px' : '35px'
                }
                color='#DD4078'
              >
                OUR TEAM OF PROFESSIONAL PROGRAMMERS
              </Text>
            </VStack>
            <Box mt='24' mb='44' w={840} h={548}>
              <IDEImage />
            </Box>
          </Flex>
        </>
      ) : null}
    </Layout>
  );
};

export default OurProjectsPage;

export const query = graphql`
  query {
    strapi {
      projects {
        data {
          attributes {
            title
            description
            image {
              data {
                attributes {
                  url
                  alternativeText
                  height
                }
              }
            }
          }
        }
      }
    }
    strapi {
      portfolio {
        data {
          attributes {
            page {
              title
              subtitle
            }
          }
        }
      }
    }
  }
`;
